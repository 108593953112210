import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';

import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage';
import SettingsPage from './pages/SettingsPage';

function App() {
  return (
    <div className='container'>
			<Switch>

				{/* <Route exact path="/market/:name/:marketMarkets/:id" component={TickerPage} />
				<Route exact path="/market/markets/:name" component={ListPage} />
				<Route path="/market/:name/:id" component={TickerPage} />
				<Route path="/market/:name" component={ListPage} /> */}

        <Route path='/settings' component={SettingsPage} />

        <Route path='/login' component={LoginPage} />

				<Route path='/' component={HomePage} />

			</Switch>
		</div>
  );
}

export default App;